const initialState = {
  isFetchingProductByArea: false,
  productByAreaList: [],
  generateIssuancePDFList: [],
  isFetchingGenerateIssuancePDF: false,
  isFetchingIssuanceData: false,
  issuanceList: [],
  isAddingIssuance: false,
  issuanceData: [],
  paymentData: [],
  isAddingPayment: false,
  isFetchingStatusIssuanceData: false,
  issuanceStatusList: [],
  paymentList:[],
  isFetchingPayment:false,
  tableShow:false,
  isFetchingIssuance:false,
  issuanceList:[],
  tableShowIssuance:false
};

const CreateIssuanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_PRODUCT_BY_AREA":
      return { ...state, isFetchingProductByArea: true };
    case "SUCCESS_GET_PRODUCT_BY_AREA":
      return {
        ...state,
        productByAreaList: action.payload,
        isFetchingProductByArea: false,
      };
    case "ERROR_GET_PRODUCT_BY_AREA":
      return { ...state, isFetchingProductByArea: false };
    case "REQUEST_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: true };
    case "SUCCESS_ADD_ISSUANCE":
      return {
        ...state,
        issuanceData: action.payload,
        isAddingIssuance: false,
      };
    case "ERROR_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: false };
    case "REQUEST_GET_ISSUANCE":
      return { ...state, isFetchingIssuanceData: true };
    case "SUCCESS_GET_ISSUANCE":
      return {
        ...state,
        issuanceList: action.payload.data,
        isFetchingIssuanceData: false,
      };
    case "ERROR_GET_ISSUANCE":
      return { ...state, isFetchingIssuanceData: false };
    case "REQUEST_GENERATE_ISSUANCE_PDF":
      return { ...state, isFetchingGenerateIssuancePDF: true };
    case "SUCCESS_GENERATE_ISSUANCE_PDF":
      return {
        ...state,
        generateIssuancePDFList: action.payload.data,
        isFetchingGenerateIssuancePDF: false,
      };
    // Add Payment
    case "REQUEST_ADD_PAYMENT":
      return { ...state, isAddingPayment: true };
    case "SUCCESS_ADD_PAYMENT":
      return {
        ...state,
        paymentData: action.payload,
        isAddingPayment: false,
      };
    case "ERROR_ADD_PAYMENT":
      return { ...state, isAddingPayment: false };

    // Get Issuance By Return

    case "REQUEST_GET_ISSUANCE_BY_STATUS":
      return { ...state, isFetchingStatusIssuanceData: true };
    case "SUCCESS_GET_ISSUANCE_BY_STATUS":
      return {
        ...state,
        issuanceStatusList: action.payload.results,
        isFetchingStatusIssuanceData: false,
      };
    case "ERROR_GET_ISSUANCE_BY_STATUS":
      return { ...state, isFetchingPayment: false };
      case "REQUEST_GET_PAYMENT_BY_DATE":
        return { ...state, isFetchingOrderFilter: true,tableShow:false };
      case "SUCCESS_GET_PAYMENT_BY_DATE":
        return {
          ...state,
          paymentList: action.payload.data,
          isFetchingPayment: false,
          tableShow:true
        };
      case "ERROR_GET_PAYMENT_BY_DATE":
        return { ...state, isFetchingPayment: false,tableShow:false };

        case "REQUEST_GET_ISSUANCE_BY_DATE":
          return { ...state, isFetchingIssuance: true,tableShowIssuance:false };
        case "SUCCESS_GET_ISSUANCE_BY_DATE":
          return {
            ...state,
            issuanceList: action.payload.data,
            isFetchingIssuance: false,
            tableShowIssuance:true
          };
        case "ERROR_GET_ISSUANCE_BY_DATE":
          return { ...state, isFetchingIssuance: false,tableShowIssuance:false };
    default:
      return state;
  }
};
export default CreateIssuanceReducer;
