import API from "../../../../../global/api";
import jsPDF from "jspdf";
import { sumBy } from "lodash";
import moment from "moment";
export const requestAddExternalReceiving = () => {
  return {
    type: "REQUEST_ADD_EXTERNAL_RECEIVING",
  };
};
export const successAddExternalReceiving = (data) => {
  return {
    type: "SUCCESS_ADD_EXTERNAL_RECEIVING",
    payload: data,
  };
};
export const errorAddExternalReceiving = () => {
  return {
    type: "ERROR_ADD_EXTERNAL_RECEIVING",
  };
};
export const addExternalReceiving = (
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestAddExternalReceiving());
    API.post(`/externalReceving/`, data)
      .then((res) => {
        dispatch(successAddExternalReceiving(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddExternalReceiving());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetExternalReceiving = () => {
  return {
    type: "REQUEST_GET_EXTERNAL_RECEIVING",
  };
};
export const successGetExternalReceiving = (data) => {
  return {
    type: "SUCCESS_GET_EXTERNAL_RECEIVING",
    payload: data,
  };
};
export const errorGetExternalReceiving = () => {
  return {
    type: "ERROR_GET_EXTERNAL_RECEIVING",
  };
};
export const getExternalReceiving = (data) => {
  return (dispatch) => {
    dispatch(requestGetExternalReceiving());
    API.get(`/externalReceving/?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetExternalReceiving(data1));
      })
      .catch((error) => {
        dispatch(errorGetExternalReceiving());
      });
  };
};

export const UpdateRequestExternalReceiving = () => {
  return {
    type: "UPDATE_REQUEST_EXTERNAL_RECEIVING",
  };
};
export const UpdateSuccessExternalReceiving = (data) => {
  return {
    type: "UPDATE_SUCCESS_EXTERNAL_RECEIVING",
    payload: data,
  };
};

export const UpdateErrorExternalReceiving = () => {
  return {
    type: "UPDATE_ERROR_EXTERNAL_RECEIVING",
  };
};

export const updateExternalReceiving = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestExternalReceiving());
    API.put(
      `/externalReceving/${oldData._id}`,
      {
        receivedBy: data.receivedBy,
        recivingDescription: data.recivingDescription,
        // location: data.location,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessExternalReceiving([data]));
      })
      .catch((error) => dispatch(UpdateErrorExternalReceiving()));
  };
};
export const DeleteRequestExternalReceiving = () => {
  return {
    type: "DELETE_REQUEST_EXTERNAL_RECEIVING",
  };
};
export const DeleteSuccessExternalReceiving = (id) => {
  return {
    type: "DELETE_SUCCESS_EXTERNAL_RECEIVING",
    id: id,
  };
};
export const DeleteErrorExternalReceiving = () => {
  return {
    type: "DELETE_ERROR_EXTERNAL_RECEIVING",
  };
};

export const deleteExternalReceiving = (id) => {
  return (dispatch) => {
    dispatch(DeleteRequestExternalReceiving());
    API.delete(`/externalReceving/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessExternalReceiving(id));
      })
      .catch((error) => dispatch(DeleteErrorExternalReceiving()));
  };
};

export const pdfExternalReceiving = (data) => {
  return(dispatch)=>{
  console.log(data,"testtttttttttt")
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "External Receiving Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
    const IssuanceDate = data.receivingDate;
    const issuedBy = data.receivedBy;
    const issuedDesc = data.receivingDescription;
   
    const headers = [
      ["Product NAME", "Quantity", "Rate", "Amount"],
    ];
    const data2 = data.data.map((elt, index) => [
      elt.productLabel,
      elt.quantity,
      elt.productSellingPrice,
      (elt.productSellingPrice*elt.quantity).toFixed(2),
    ]);
    const Total = sumBy(data2, "1");
    const TotalAmount = sumBy(data2, "3");
    let content = {
      startY: 200,
      startX: 10,
      head: headers,
      body: data2,
    };
    doc.setFontSize(20);
    doc.setFont(undefined, "bold");
    doc.text(companyName, 40, 50, 0, 20);
    doc.setFontSize(13);
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(companyAddress, 40, 70, 0, 20);
    doc.text(`Ph: ${companyPhone}`, 40, 90, 0, 20);
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text(title, 240, 120);
    doc.setFontSize(14);
    doc.setFont(undefined, "normal");
    doc.text(`Issued Date: ${IssuanceDate}`,40,140)
    doc.text(`Issued By: ${issuedBy}`,40,160)
    doc.text(`Description: ${issuedDesc}`,40,180)
    doc.autoTable(content);
    doc.text(
      `Total Quantity: ${Total.toFixed(2)}`,
      400,
      doc.lastAutoTable.finalY + 20,
      0,
      20
    );
    doc.text(
      `Total Amount: ${TotalAmount}`,
      400,
      doc.lastAutoTable.finalY + 40,
      0,
      20
    );
    doc.setProperties(
      `External Receiving Report ${moment(new Date()).format("YYYY-MM-DD")}`
    );
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  }
};

export const requestGetExternalReceivingByDate = () => {
  return {
    type: "REQUEST_GET_EXTERNAL_RECEIVING_BY_DATE",
  };
};
export const successGetExternalReceivingByDate = (data) => {
  return {
    type: "SUCCESS_GET_EXTERNAL_RECEIVING_BY_DATE",
    payload: data,
  };
};
export const errorGetExternalReceivingByDate = () => {
  return {
    type: "ERROR_GET_EXTERNAL_RECEIVING_BY_DATE",
  };
};
export const getExternalReceivingByDate = (data) => {
  return (dispatch) => {
    dispatch(requestGetExternalReceivingByDate());
    API.post(`/externalReceving/external_receiving_with_dates`,data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetExternalReceivingByDate(data1));
      })
      .catch((error) => {
        dispatch(errorGetExternalReceivingByDate());
      });
  };
};

export const DeleteRequestExternalReceivingProduct = () => {
  return {
    type: "DELETE_REQUEST_EXTERNAL_RECEIVING_PRODUCT",
  };
};
export const DeleteSuccessExternalReceivingProduct = (id) => {
  return {
    type: "DELETE_SUCCESS_EXTERNAL_RECEIVING_PRODUCT",
    id: id,
  };
};
export const DeleteErrorExternalReceivingProduct = () => {
  return {
    type: "DELETE_ERROR_EXTERNAL_RECEIVING_PRODUCT",
  };
};

export const deleteExternalReceivingProduct = (data,rowdata,updateTableQuery) => {
  return (dispatch) => {
    dispatch(DeleteRequestExternalReceivingProduct());
    API.post(`/externalReceving/delete_ex_receiving_product/${rowdata._id}`,{
      _id:data._id,
      product:data.product,
      quantity:data.quantity
    })
      .then((res) => {
        dispatch(DeleteSuccessExternalReceivingProduct(rowdata._id));
        updateTableQuery()
      })
      .catch((error) => dispatch(DeleteErrorExternalReceivingProduct()));
  };
};

export const UpdateRequestExternalReceivingProduct = () => {
  return {
    type: "UPDATE_REQUEST_EXTERNAL_RECEIVING_PRODUCT",
  };
};
export const UpdateSuccessExternalReceivingProduct = (data) => {
  return {
    type: "UPDATE_SUCCESS_EXTERNAL_RECEIVING_PRODUCT",
    payload: data,
  };
};

export const UpdateErrorExternalReceivingProduct = () => {
  return {
    type: "UPDATE_ERROR_EXTERNAL_RECEIVING_PRODUCT",
  };
};

export const updateExternalReceivingProduct = (data, oldData,rowData,updateTableQuery) => {
  return (dispatch) => {
    dispatch(UpdateRequestExternalReceivingProduct());
    API.put(
      `/externalReceving/update_ex_receiving_product/${rowData._id}`,
      {
        _id:data._id,
        product:data.product,
        quantity:data.quantity,
        difference:Number(data.quantity)-Number(oldData.quantity),
        productSellingPrice:data.productSellingPrice
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessExternalReceivingProduct([data]));
        updateTableQuery()
      })
      .catch((error) => dispatch(UpdateErrorExternalReceivingProduct()));
  };
};
