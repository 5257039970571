import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, subtract, find, sumBy, filter, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import {
  addPayment,
  getIssuance,
  getIssuanceById,
  getIssuanceByStatus,
  getProductByArea,
} from "../CreateIssuance/Issuance.action";

const AddPayment = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [credit, setCredit] = useState(0);
  const [creditRec, setCreditRes] = useState(0);
  const [shopName, setShopName] = useState("");
  const [shopId, setShopId] = useState();
  const [issuanceName, setIssuanceName] = useState("");
  const [issuanceId, setIssuanceId] = useState();
  const [inputField, setInputField] = useState([]);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleQuantityChange = (id, index, quantity, type) => {
    console.log(id, index, quantity, "aaaaaaaaaaaaaaaaa");
    const list = [...inputField];
    if (type == "quantity") {
      list[index]["product"] = "test";
      list[index]["quantity"] = quantity;
    }
    setInputField(list);
  };

  function uploadPayment() {
    console.log(inputField,"vvvvvvvv")
    !isEmpty(props.generateIssuancePDFList) &&
      props.generateIssuancePDFList[0].data.map((item, i) => {
        !isEmpty(props.generateIssuancePDFList) &&
          props.generateIssuancePDFList[0].returnProducts.map((item1, j) => {
            if (item.product._id == item1.product) {
              inputField.push({
                product: item.product._id,
                quantity: Number(item.quantity) //- Number(item1.quantity),
              });
              // handleQuantityChange(item.product._id, i, Number(item.quantity) - Number(item1.quantity),"quantity")
            }
          });
      });
    console.log(inputField, "aaaaaaaaaaaaaaaaaaaa1122");
    !isEmpty(props.generateIssuancePDFList) &&
      props.generateIssuancePDFList[0].data.map((item, i) => {
        let a = find(inputField, { product: item.product._id });
        console.log(a, "ttttttt");
        isUndefined(a) &&
          inputField.push({
            product: item.product._id,
            quantity: Number(item.quantity),
          });
      });

    props.addPayment(
      issuanceId,
      {
        data: inputField,
        credit:credit,
        creditReceive:creditRec,
        subTotal: sumBy(
          props.generateIssuancePDFList[0].data,
          "totalSaleValue"
        ),
        returnAmount: sumBy(
          props.generateIssuancePDFList[0].returnProducts,
          (x) => {
            return Number(x.productSellingPrice * x.quantity);
          }
        ),
        bookingDiscount: props.generateIssuancePDFList.bookingDiscount,
        finalPaidAmount:
          Number(
            sumBy(props.generateIssuancePDFList[0].data, "totalSaleValue")
          ) -
          Number(
            sumBy(props.generateIssuancePDFList[0].returnProducts, (x) => {
              return Number(x.productSellingPrice * x.quantity);
            })
          ) -
          Number(props.generateIssuancePDFList[0].bookingDiscount)-Number(credit)+Number(creditRec),
      },

      handleVisible,
      handleDangerVisible
    );
    setInputField([]);
    setIssuanceId();
    setIssuanceName("");
  }
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
    props.getShopsByArea(selectedOption.value);
  };
  const AreaListData =
    !isEmpty(props.areaDataList) &&
    props.areaDataList.map((x) => {
      let data = { value: x._id, label: x.areaName };
      return data;
    });
  const handleChangeShop = (selectedOption) => {
    setShopId(selectedOption.value);
    setShopName(selectedOption.label);
  };
  const ShopListData =
    !isEmpty(props.shopsByAreaList) &&
    props.shopsByAreaList.map((x) => {
      let data = { value: x._id, label: x.shopName };
      return data;
    });
  const issuanceStatusListData =
    !isEmpty(props.issuanceStatusList) &&
    props.issuanceStatusList.map((x) => {
      let data = {
        value: x._id,
        label: `${x.date}/${x.areaId.areaName}/${isUndefined(x.bookerDetails)?"":x.bookerDetails.userName}/${x.salesMenId == null ? "" : x.salesMenId.name
          }`,
      };
      return data;
    });
  // /${x.salesMenId.name}
  // console.log(props.issuanceList, "Listttttt");
  const handleChangeIssuance = (selectedOption) => {
    setIssuanceId(selectedOption.value);
    setIssuanceName(selectedOption.label);
    props.getIssuanceById(selectedOption.value);
  };
  useEffect(() => {
    props.setHeading("Add Payment");
    // props.getIssuance();
    props.getIssuanceByStatus();
  }, []);

  return (
    <>
      {props.isFetchingIssuanceData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Issuance *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.issuanceStatusList)}
                    value={{ label: issuanceName }}
                    placeholder="Select Issuance..."
                    onChange={handleChangeIssuance}
                    options={issuanceStatusListData}
                  />
                </Form.Group>
              </Col>
            </Row>
            {props.isFetchingGenerateIssuancePDF ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div hidden={isEmpty(issuanceName)}>
                {props.isFetchingGenerateIssuancePDF ? (
                  <div className="loader-div">
                    <Spinner
                      variant="primary"
                      animation="grow"
                      size="xl"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col xl="4" lg="4" md="6" xs="6">
                        <b>Product</b>
                      </Col>
                      <Col>
                        <b>Quantity</b>
                      </Col>
                      <Col>
                        <b>Rate</b>
                      </Col>
                      <Col>
                        <b>Return</b>
                      </Col>
                      <Col>
                        <b>Total</b>
                      </Col>
                    </Row>
                    {console.log(
                      props.generateIssuancePDFList,
                      "generateIssuancePDFList"
                    )}
                    {!isEmpty(props.generateIssuancePDFList) &&
                      props.generateIssuancePDFList[0].data.map((item, i) => {
                        console.log(item, "jjjj");
                        return (
                          <React.Fragment key={i}>
                            <Row>
                              <Col xl="4" lg="4" md="6" xs="6">
                                {item.productLabel}
                              </Col>
                              <Col>{item.quantity}</Col>
                              <Col>{item.productSellingPrice}</Col>
                              <Col>
                                {" "}
                                {!isEmpty(props.generateIssuancePDFList) &&
                                  props.generateIssuancePDFList[0].returnProducts.map(
                                    (item1, i) => {
                                      console.log(item, item1, "jjjj11");
                                      return (
                                        <React.Fragment key={i}>
                                          <Row>
                                            <Col>
                                              {item.product._id ==
                                                item1.product && item1.quantity}
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </Col>
                              <Col>{item.totalSaleValue}</Col>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                    <Row style={{ marginTop: 10 }}>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Sub Total </b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            disabled
                            value={!isEmpty(props.generateIssuancePDFList) &&
                              sumBy(
                                props.generateIssuancePDFList[0].data,
                                "totalSaleValue"
                              )}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Returns </b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            disabled
                            value={!isEmpty(props.generateIssuancePDFList) &&
                              sumBy(
                                props.generateIssuancePDFList[0].returnProducts,
                                (x) => {
                                  return Number(
                                    x.productSellingPrice * x.quantity
                                  );
                                }
                              )}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Discount </b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            disabled
                            value={!isEmpty(props.generateIssuancePDFList) &&
                              props.generateIssuancePDFList[0].bookingDiscount}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Total </b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            disabled
                            value={Number(
                              !isEmpty(props.generateIssuancePDFList) &&
                              sumBy(
                                props.generateIssuancePDFList[0].data,
                                "totalSaleValue"
                              )
                            ) -
                              Number(
                                !isEmpty(props.generateIssuancePDFList) &&
                                sumBy(
                                  props.generateIssuancePDFList[0]
                                    .returnProducts,
                                  (x) => {
                                    return Number(
                                      x.productSellingPrice * x.quantity
                                    );
                                  }
                                )
                              ) -
                              Number(
                                !isEmpty(props.generateIssuancePDFList) &&
                                props.generateIssuancePDFList[0].bookingDiscount
                              )}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Credit</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={credit}
                            onChange={(e)=>{setCredit(e.target.value)}}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Credit Receive</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={creditRec}
                            onChange={(e)=>{setCreditRes(e.target.value)}}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Grand Total </b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            disabled
                            value={Number(
                              !isEmpty(props.generateIssuancePDFList) &&
                              sumBy(
                                props.generateIssuancePDFList[0].data,
                                "totalSaleValue"
                              )
                            ) -
                              Number(
                                !isEmpty(props.generateIssuancePDFList) &&
                                sumBy(
                                  props.generateIssuancePDFList[0]
                                    .returnProducts,
                                  (x) => {
                                    return Number(
                                      x.productSellingPrice * x.quantity
                                    );
                                  }
                                )
                              ) -
                              Number(
                                !isEmpty(props.generateIssuancePDFList) &&
                                props.generateIssuancePDFList[0].bookingDiscount
                              )-Number(credit)+Number(creditRec)
                            }
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="sendDiv">
                      {issuanceId == "" ? (
                        <Button disabled>Save</Button>
                      ) : (
                        <Button onClick={uploadPayment}>Receive Payment</Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getIssuance: () => dispatch(getIssuance()),
    getIssuanceByStatus: () => dispatch(getIssuanceByStatus()),
    setHeading: (data) => dispatch(setHeading(data)),
    addPayment: (id, data, handleVisible, handleDangerVisible) =>
      dispatch(addPayment(id, data, handleVisible, handleDangerVisible)),
    getIssuanceById: (id) => dispatch(getIssuanceById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  shopsByAreaList: state.ShopReducer.shopsByAreaList,
  isFetchingShopsByArea: state.ShopReducer.isFetchingShopsByArea,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  issuanceStatusList: state.CreateIssuanceReducer.issuanceStatusList,
  isFetchingStatusIssuanceData:
    state.CreateIssuanceReducer.isFetchingStatusIssuanceData,
  // issuanceList: state.CreateIssuanceReducer.issuanceList,
  // isFetchingIssuanceData: state.CreateIssuanceReducer.isFetchingIssuanceData,
  generateIssuancePDFList: state.CreateIssuanceReducer.generateIssuancePDFList,
  isFetchingGenerateIssuancePDF:
    state.CreateIssuanceReducer.isFetchingGenerateIssuancePDF,
  paymentData: state.CreateIssuanceReducer.paymentData,
  isAddingPayment: state.CreateIssuanceReducer.isAddingPayment,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
