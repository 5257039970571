import React, { useEffect, useState } from "react";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { setHeading } from "../../../accounts/account.actions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Select from "react-select";
import moment from "moment";
import { getVendorData } from "../Vendor/Vendor.action";
import { deleteProduct, filterProduct, updateProduct } from "./Product.action";

const ViewProductByVendorList = (props) => {
  const navigate = useNavigate();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };

  // function uploadOrder() {
  //   props.filterProduct(vendorId);
  // }
  useEffect(() => {
    props.setHeading("Vendor wise Product List");
    props.getVendorData()
  }, []);
  return (
    <>
      {props.isFetchingVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col style={{ zIndex: 5 }}>
              <Form.Group className="mb-3 mt-3">
                <Form.Label>
                  <b>Select Vendor *</b>
                </Form.Label>
                <Select
                  value={{ label: vendorName }}
                  isDisabled={
                    isEmpty(props.vendorDataList)
                  }
                  placeholder="Select Vendor..."
                  onChange={handleChangeVendor}
                  options={getVendorList}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">

            {isEmpty(vendorId) ? (
              <Button disabled>Get Product</Button>
            ) : (
              props.isFetchingVendor ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button onClick={() => { props.filterProduct(vendorId) }}>Get Product</Button>)
            )}
          </div>
          {console.log(props.filterProductList, props.tableShow, "ffffffffffffffff")}

          <div className="main" hidden={props.tableShow == false ? true : false} >
            <MaterialTable
              style={{ zIndex: 1 }}
              title="Vendor Wise Product Details"
              // title=Vendor Wise Product Details`
              columns={[
                { title: "Product Code", field: "code", editable: "never" },
                {
                  title: "Product Name",
                  field: "name",
                  render: (rowData) => (
                    <div
                      style={{
                        backgroundColor:
                          rowData.productTypeName == "Service Product"
                            ? "transparent"
                            : Number(rowData.productBalance) <=
                              Number(rowData.minimumBalance)
                              ? "#ff8d8d"
                              : Number(rowData.productBalance) >=
                                Number(rowData.maximumBalance)
                                ? "#addfac"
                                : "transparent",
                      }}
                    >
                      {" "}
                      {rowData.name}
                    </div>
                  ),
                },
                // { title: "Product Discount", field: "discount" },
                { title: "Product Description", field: "desc" },
                { title: "opening Balance", field: "openingBalance" },
                {
                  title: "Product Balance",
                  field: "productBalance",
                  // editable: "never",
                },
                // { title: "product Type Name", field: "productTypeName",editable:'never' },
                { title: "Product Purchase Price", field: "purchasePrice" },
                { title: "Product Sale Price", field: "salePrice" },
                { title: "Product Wholesale Price", field: "wholesalePrice" },
                // { title: "Product Red Color Time", field: "redColorTime" },
                // { title: "Product Yellow Color Time", field: "yellowColorTime" },
                // { title: "Product Green Color Time", field: "greenColorTime" },//uomPrice
                {
                  title: 'Vendor Name',
                  field: "vendorId.vendorName",
                  editComponent: ({ value, onChange }) => (
                    // onChange(e.target.value)
                    // console.log(e.target.value,"eeeeeeeeeeeeeee")
                    <select onChange={(e) => onChange(e.target.value)
                    } style={{ padding: '10px' }}>
                      <option selected value={value}>
                        {value}
                      </option>
                      {props.vendorDataList.map(
                        (item) =>
                          // console.log(item,"iiiiiiiiiiiiiiiiiiii")
                          item._id !== value && (
                            console.log(item, value, "tttttttt"),
                            <option key={item._id} value={item.vendorName} id={item._id}>
                              {item.vendorName}
                            </option>
                          )
                      )}
                    </select>
                  )
                },
                {
                  title: "Warehouse",
                  field: "wareHouseId.wareHouseName",
                  editable: "never",
                },
                {
                  title: "Category",
                  field: "category.name",
                  editable: "never",
                },
                {
                  title: "Sub Category",
                  field: "subCategoryId.subCategoryName",
                  editable: "never",
                },
              ]}
              data={props.filterProductList}

              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Product Edit"),
                isDeleteHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Product Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateProduct(newData, oldData, props.vendorDataList);
                      }
                    }, 600);
                  }),
                isDeletable: false,
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteProduct(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {

    setHeading: (data) => dispatch(setHeading(data)),
    getVendorData: () => dispatch(getVendorData()),
    filterProduct: (id) => dispatch(filterProduct(id)),
    deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    updateProduct: (newData, oldData, arrayData) => dispatch(updateProduct(newData, oldData, arrayData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
  tableShow: state.ProductReducer.tableShow,
  filterProductList: state.ProductReducer.filterProductList,
  isFetchingProductFilter:
    state.ProductReducer.isFetchingProductFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProductByVendorList);
