import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Navbar, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getUserRes } from "../../AddUser/user.actions";
import {
  addReporting,
  generateNetSaleReportPDF,
  generateSaleReportPDF,
} from "./Reporting.action";
import moment from "moment";
import IconMenu from "../../../drawer/IconMenu";
import { Link } from "react-router-dom";

const AddReporting = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bookerId, setBookerId] = useState("");
  const [bookerName, setBookerName] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Data not Found",
      icon: "error",
      button: "Ok",
    });
  };

  function getSaleReport() {
    props.generateSaleReportPDF(
      bookerId,
      {
        startDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        // bookerId: bookerId,
      },
      startDate,
      endDate,
      // handleVisible,
      handleDangerVisible
    );
    setStartDate("");
    setEndDate("");
  }
  function getNetSaleReport() {
    props.generateNetSaleReportPDF(
      bookerId,
      {
        startDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        // bookerId: bookerId,
      },
      startDate,
      endDate
      //   handleVisible,
      //   handleDangerVisible
    );
    setStartDate("");
    setEndDate("");
  }
  const filterdUserList =
    !isEmpty(props.userList) &&
    props.userList.filter((e) => e.role == "booker"||"bookerwholesale");

  const BookerList =
    !isEmpty(filterdUserList) &&
    filterdUserList.map((x) => {
      let data = { value: x._id, label: x.userName };
      return data;
    });
  const handleChangeBooker = (selectedOption) => {
    setBookerId(selectedOption.value);
    setBookerName(selectedOption.label);
  };

  useEffect(() => {
    props.setHeading("Sale Report");
    props.getUser();
  }, []);
  return (
    <>
      <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Sale Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      {props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
          <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Sale Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Start Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    // min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>End Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Booker *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(BookerList)}
                    value={{ label: bookerName }}
                    placeholder="Select Booker..."
                    onChange={handleChangeBooker}
                    options={BookerList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {/* <Row>
                <Col> */}
              {isEmpty(startDate) || isEmpty(endDate) || bookerName == "" ? (
                <Button disabled>Generate Sale Report</Button>
              ) : (
                <Button onClick={getSaleReport}>Generate Sale Report</Button>
              )}
              {/* </Col> */}
              {/* <Col>
                  {isEmpty(startDate) ||
                  isEmpty(endDate) ||
                  bookerName == "" ? (
                    <Button disabled>Generate Net Sale Report</Button>
                  ) : (
                    <Button onClick={getNetSaleReport}>
                      Generate Net Sale Report
                    </Button>
                  )}
                </Col>
              </Row> */}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    getUser: () => dispatch(getUserRes()),
    generateSaleReportPDF: (
      id,
      data,
      startDate,
      endDate,
      handleDangerVisible
    ) =>
      dispatch(
        generateSaleReportPDF(id, data, startDate, endDate, handleDangerVisible)
      ),
    generateNetSaleReportPDF: (id, data, startDate, endDate) =>
      dispatch(generateNetSaleReportPDF(id, data, startDate, endDate)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  isAddingReporting: state.ReportingReducer.isAddingReporting,
  reportingData: state.ReportingReducer.reportingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReporting);
