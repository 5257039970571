import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Collapse from "@mui/material/Collapse";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import StarBorder from "@mui/icons-material/StarBorder";
import { NavLink } from "react-router-dom";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import DvrIcon from "@mui/icons-material/Dvr";
import DoorSlidingIcon from "@mui/icons-material/DoorSliding";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ListIcon from "@mui/icons-material/List";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CreateIcon from "@mui/icons-material/Create";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReorderIcon from "@mui/icons-material/Reorder";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import AddchartIcon from "@mui/icons-material/Addchart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import GridViewIcon from "@mui/icons-material/GridView";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import AddHomeIcon from "@mui/icons-material/AddHome";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaymentIcon from "@mui/icons-material/Payment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import SearchIcon from "@mui/icons-material/Search";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BadgeIcon from "@mui/icons-material/Badge";
// import AddBoxIcon from '@mui/icons-material/AddBox';
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import TopicIcon from "@mui/icons-material/Topic";
import PollIcon from "@mui/icons-material/Poll";
import TableChartIcon from "@mui/icons-material/TableChart";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PublishIcon from "@mui/icons-material/Publish";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import UploadIcon from "@mui/icons-material/Upload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SourceIcon from "@mui/icons-material/Source";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DiscountIcon from "@mui/icons-material/Discount";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import StraightenIcon from "@mui/icons-material/Straighten";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RuleIcon from "@mui/icons-material/Rule";

export default function Menu(props) {
  console.log(props, "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm");
  //  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // const permissions = );
  const [open, setOpen] = React.useState(false);
  const [demand, setDemand] = React.useState(false);
  const [purchaseorder, setPurchaseorder] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [openCoupon, setOpenCoupon] = React.useState(false);
  const [openArea, setOpenArea] = React.useState(false);
  const [openOrderBooking, setOpenOrderBooking] = React.useState(false);
  const [openReporting, setOpenReporting] = React.useState(false);
  const [openCreateIssuance, setOpenCreateIssuance] = React.useState(false);
  const [openShop, setOpenShop] = React.useState(false);
  const [openIssuanceByVendor, setOpenIssuanceByVendor] = React.useState(false);
  const [openReceivingByVendor, setOpenReceivingByVendor] =
    React.useState(false);
  const [openVendor, setOpenVendor] = React.useState(false);
  const [openSize, setOpenSize] = React.useState(false);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openReciving, setOpenReciving] = React.useState(false);
  const [openUom, setOpenUom] = React.useState(false); //Reciving
  const [openRecipe, setOpenRecipe] = React.useState(false);
  const [openDeal, setOpenDeal] = React.useState(false);
  const [openRestaurant, setOpenRestaurant] = React.useState(false);
  // const [openVendor, setOpenVendor] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);
  const [openMasterControl, setOpenMasterControl] = React.useState(false); //MasterControl
  const [openhall, setOpenhall] = React.useState(false);
  const [openIssuance, setOpenissuance] = React.useState(false);

  const [openFlavour, setOpenFlavour] = React.useState(false);
  const [generateBarcode, setGenerateBarCode] = React.useState(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);

  const [opensubCategory, setOpensubCategory] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [igp, setIgp] = React.useState(false);
  const [inventory, setInventory] = React.useState(false);
  const [issuance, setIssuance] = React.useState(false);
  const [returnVendors, setReturnVendors] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [processVendors, setProcessVendors] = React.useState(false);

  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [level2, setLevel2] = React.useState(false);
  const [level3, setLevel3] = React.useState(false);
  const [level4, setLevel4] = React.useState(false);
  const [level5, setLevel5] = React.useState(false);
  // const [material, setMaterial] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const buttonProps = (value) => ({
    selected: selectedIndex === value,
    onClick: () => setSelectedIndex(value),
  });
  const Checkpermissions = (permissionsType, permissionsName) => {
    return props.permissions.some(
      (p) => p[`${permissionsType}`] === permissionsName
    )
      ? true
      : false;
  };
  console.log(window.localStorage.getItem("module_key"), "oooo");

  // Admin Listing for Inventoryprops.permissions.some(p => p['permissionsCatName'] === "Master Control")

  let storeList = (
    <List>
      {/* Warehouse */}
      {Checkpermissions("permissionsCatName", "WareHouse") && (
        <ListItemButton onClick={() => setOpenRestaurant(!openRestaurant)}>
          <ListItemIcon>
            <RestaurantIcon />
          </ListItemIcon>
          <ListItemText primary="Warehouse" />
          {openRestaurant ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openRestaurant} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/warehouse/addWarehouse">
            {Checkpermissions("permissionsSubCatName", "WareHouse Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Warehouse" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/warehouse/listWarehouse">
            {Checkpermissions("permissionsSubCatName", "WareHouse List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Warehouse List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Category */}
      {Checkpermissions("permissionsCatName", "Category") && (
        <ListItemButton onClick={() => setOpenCategory(!openCategory)}>
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Catagory" />
          {openCategory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openCategory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/catagory/addcatagory">
            {Checkpermissions("permissionsSubCatName", "Category Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Catagory" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/catagory/listcatagory">
            {Checkpermissions("permissionsSubCatName", "Category List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Catagory List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* vendor */}
      {Checkpermissions("permissionsCatName", "Vendor") && (
        <ListItemButton onClick={() => setOpenVendor(!openVendor)}>
          <ListItemIcon>
            <PersonAddAlt1Icon />
          </ListItemIcon>
          <ListItemText primary="Vendor" />
          {openVendor ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openVendor} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Vendor/addVendor">
            {Checkpermissions("permissionsSubCatName", "Vendor Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Vendor" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Vendor/listVendor">
            {Checkpermissions("permissionsSubCatName", "Vendor List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Vendor List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* SubCategory */}
      {/* {Checkpermissions("permissionsCatName", "SubCategory") && (
        <ListItemButton onClick={() => setOpenSubCategory(!openSubCategory)}>
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Sub Catagory" />
          {openSubCategory ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openSubCategory} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/subcategory/addsubcategory">
            {Checkpermissions("permissionsSubCatName", "SubCategory Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New SubCatagory" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/subcategory/listsubcategory">
            {Checkpermissions("permissionsSubCatName", "SubCategory List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="SubCatagory List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse> */}

      {/* Product */}
      {Checkpermissions("permissionsCatName", "Product") && (
        <ListItemButton onClick={() => setOpenProduct(!openProduct)}>
          <ListItemIcon>
            <FastfoodIcon />
          </ListItemIcon>
          <ListItemText primary="Product" />
          {openProduct ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openProduct} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Product/addProduct">
            {Checkpermissions("permissionsSubCatName", "Product Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Product" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/product/uploadProduct">
            {Checkpermissions("permissionsSubCatName", "Product Upload") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Upload Product" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/Product/listProduct">
            {Checkpermissions("permissionsSubCatName", "Product List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Product List" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Product/vendorWiselistProduct">
            {Checkpermissions("permissionsSubCatName", "Product List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Vendor Wise Product List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Area */}
      {Checkpermissions("permissionsCatName", "Area") && (
        <ListItemButton onClick={() => setOpenArea(!openArea)}>
          <ListItemIcon>
            <DiscountIcon />
          </ListItemIcon>
          <ListItemText primary="Area" />
          {openArea ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openArea} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Area/addArea">
            {Checkpermissions("permissionsSubCatName", "Area Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Area" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Area/listArea">
            {Checkpermissions("permissionsSubCatName", "Area List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Area List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Shop */}
      {Checkpermissions("permissionsCatName", "Shop") && (
        <ListItemButton onClick={() => setOpenShop(!openShop)}>
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText primary="Shop" />
          {openShop ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openShop} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Shop/addShop">
            {Checkpermissions("permissionsSubCatName", "Shop Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Shop" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Shop/listShop">
            {Checkpermissions("permissionsSubCatName", "Shop List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Shop List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Customer */}
      {Checkpermissions("permissionsCatName", "Customer") && (
        <ListItemButton onClick={() => setOpenCustomer(!openCustomer)}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="SalesMan" />
          {openCustomer ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openCustomer} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Customer/addCustomer">
            {Checkpermissions("permissionsSubCatName", "Customer Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Add SalesMan" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Customer/listCustomer">
            {Checkpermissions("permissionsSubCatName", "Customer List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="SalesMen List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* OrderBooking */}
      {Checkpermissions("permissionsCatName", "Order Booking") && (
        <ListItemButton onClick={() => setOpenOrderBooking(!openOrderBooking)}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Order Booking" />
          {openOrderBooking ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openOrderBooking} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/OrderBooking/addOrderBooking">
            {Checkpermissions("permissionsSubCatName", "Order Booking Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Order Booking" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/OrderBooking/listOrderBooking">
            {Checkpermissions(
              "permissionsSubCatName",
              "Order Booking List"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Order Booking List" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/OrderBooking/filterOrderBooking">
            {Checkpermissions(
              "permissionsSubCatName",
              "Order Booking List"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Filtered Booking List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* CreateIssuance */}
      {Checkpermissions("permissionsCatName", "Issuance") && (
        <ListItemButton
          onClick={() => setOpenCreateIssuance(!openCreateIssuance)}
        >
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Issuance" />
          {openCreateIssuance ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openCreateIssuance} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/CreateIssuance/addCreateIssuance">
            {Checkpermissions("permissionsSubCatName", "Issuance Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Issuance" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/CreateIssuance/listCreateIssuance">
            {Checkpermissions("permissionsSubCatName", "Issuance List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Issuance List" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/CreateIssuance/IssuanceListWithDate">
            {Checkpermissions("permissionsSubCatName", "Issuance List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Issuance List With Date" />
              </ListItemButton>
            )}
          </NavLink>
          {/* <NavLink to="/inventory/CreateIssuance/payment">
             {Checkpermissions("permissionsSubCatName","Shop List")&& 
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Payment" />
            </ListItemButton>
             } 
          </NavLink> */}
        </List>
      </Collapse>
      {/* Retur */}
      {Checkpermissions("permissionsCatName", "Return") && (
        <ListItemButton onClick={() => setReturnVendors(!returnVendors)}>
          <ListItemIcon>
            <AssignmentReturnIcon />
          </ListItemIcon>
          <ListItemText primary="Return" />
          {returnVendors ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={returnVendors} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/addreturn">
            {Checkpermissions("permissionsSubCatName", "Return Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddchartIcon />
                </ListItemIcon>
                <ListItemText primary="Add Return" />
              </ListItemButton>
            )}
          </NavLink>
          {/* <NavLink to="/inventory/returnList">
            {/* {Checkpermissions("permissionsSubCatName", "Return Add") && ( */}
          {/* <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddchartIcon />
                </ListItemIcon>
                <ListItemText primary="View Returns" />
              </ListItemButton> */}
          {/* )} 
          </NavLink> */}
        </List>
      </Collapse>
      {/* payment */}
      <ListItemButton onClick={() => setPayment(!payment)}>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Payment" />
          {payment ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      <Collapse in={payment} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
      <NavLink
        to="/inventory/CreateIssuance/payment"
        // style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Payment" />
        </ListItemButton>
      </NavLink>
      <NavLink
        to="/inventory/CreateIssuance/Viewpayment"
        // style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Payment List" />
        </ListItemButton>
      </NavLink>
      <NavLink
        to="/inventory/CreateIssuance/ViewpaymentByDate"
        // style={{ textDecoration: "none", color: "black" }}
      >
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Filtered Payment List" />
        </ListItemButton>
      </NavLink>
      </List>
      </Collapse>
      {/* )} */}

      {/* Reciving */}
      {Checkpermissions("permissionsCatName", "Receiving") && (
        <ListItemButton onClick={() => setOpenReciving(!openReciving)}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Purchase" />
          {openReciving ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openReciving} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Product/addReciving">
            {Checkpermissions("permissionsSubCatName", "Receiving Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Purchase" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/Product/listReciving">
            {Checkpermissions("permissionsSubCatName", "Receiving List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Purchase List" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Product/listRecivingWithDate">
            {Checkpermissions("permissionsSubCatName", "Receiving List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Purchase List With Date" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/*inventory ledger */}
      {Checkpermissions("permissionsCatName", "Inventory Ledger") && (
        <NavLink
          to="/inventory/ledger"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Inventory Ledger" />
          </ListItemButton>
        </NavLink>
      )}

      {/* uom */}
      {Checkpermissions("permissionsCatName", "UOM") && (
        <NavLink
          to="/inventory/uomlist"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="UOM List" />
          </ListItemButton>
        </NavLink>
      )}

      {/* Issuance By Vendor */}
      {/* {Checkpermissions("permissionsCatName", "Shop") && ( */}
      <ListItemButton
        onClick={() => setOpenIssuanceByVendor(!openIssuanceByVendor)}
      >
        <ListItemIcon>
          <AddBoxIcon />
        </ListItemIcon>
        <ListItemText primary="External Issuance" />
        {openIssuanceByVendor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {/* )} */}
      <Collapse in={openIssuanceByVendor} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/ExternalIssuance/addExternalIssuance">
            {/* {Checkpermissions("permissionsSubCatName", "Shop Add") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New External Issuance" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
          <NavLink to="/inventory/ExternalIssuance/listExternalIssuance">
            {/* {Checkpermissions("permissionsSubCatName", "Shop List") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="External Issuance List" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
          <NavLink to="/inventory/ExternalIssuance/listExternalIssuanceWithDate">
            {/* {Checkpermissions("permissionsSubCatName", "Shop List") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="External Issuance List With Date" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
        </List>
      </Collapse>

      {/* Receiving By Vendor */}
      {/* {Checkpermissions("permissionsCatName", "Shop") && ( */}
      <ListItemButton
        onClick={() => setOpenReceivingByVendor(!openReceivingByVendor)}
      >
        <ListItemIcon>
          <AddBoxIcon />
        </ListItemIcon>
        <ListItemText primary="External Receiving" />
        {openReceivingByVendor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {/* )} */}
      <Collapse in={openReceivingByVendor} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/ExternalReceiving/addExternalReceiving">
            {/* {Checkpermissions("permissionsSubCatName", "Shop Add") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New External Receiving" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
          <NavLink to="/inventory/ExternalReceiving/listExternalReceiving">
            {/* {Checkpermissions("permissionsSubCatName", "Shop List") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="External Receiving List" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
          <NavLink to="/inventory/ExternalReceiving/listExternalReceivingWithDate">
            {/* {Checkpermissions("permissionsSubCatName", "Shop List") && ( */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="External Receiving List With Date" />
            </ListItemButton>
            {/* )} */}
          </NavLink>
        </List>
      </Collapse>

      {/* Reporting */}
      {/* {Checkpermissions("permissionsCatName", "Report") && (
        <ListItemButton onClick={() => setOpenReporting(!openReporting)}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>
          <ListItemText primary="Reporting" />
          {openReporting ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openReporting} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Reporting/saleReport">
            {Checkpermissions("permissionsSubCatName", "Sale Report") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Sale Report" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Reporting/netsaleReport">
            {Checkpermissions("permissionsSubCatName", "Net Sale Report") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Net Sale Report" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Reporting/stockReport">
            {Checkpermissions("permissionsSubCatName", "Stock Report") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Stock Report" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Reporting/vendorPurchaseReport">
            {Checkpermissions(
              "permissionsSubCatName",
              "Vendor Purchase Report"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Vendor Purchase Report" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse> */}
    </List>
  );

  //  storeList
  let inventoryList = (
    <List>
      {/* Flavour */}
      {Checkpermissions("permissionsCatName", "Flavour") && (
        <ListItemButton onClick={() => setOpenFlavour(!openFlavour)}>
          <ListItemIcon>
            <LocalPizzaIcon />
          </ListItemIcon>
          <ListItemText primary="Flavour" />
          {openFlavour ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openFlavour} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Flavour/addFlavour">
            {Checkpermissions("permissionsSubCatName", "Flavour Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Flavour" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Flavour/listFlavour">
            {Checkpermissions("permissionsSubCatName", "Flavour List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Flavour List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Size */}
      {Checkpermissions("permissionsCatName", "Size") && (
        <ListItemButton onClick={() => setOpenSize(!openSize)}>
          <ListItemIcon>
            <StraightenIcon />
          </ListItemIcon>
          <ListItemText primary="Size" />
          {openSize ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openSize} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Size/addSize">
            {Checkpermissions("permissionsSubCatName", "Size Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Size" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Size/listSize">
            {Checkpermissions("permissionsSubCatName", "Size List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Size List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Recipe */}
      {Checkpermissions("permissionsCatName", "Recipe") && (
        <ListItemButton onClick={() => setOpenRecipe(!openRecipe)}>
          <ListItemIcon>
            <RamenDiningIcon />
          </ListItemIcon>
          <ListItemText primary="Recipe" />
          {openRecipe ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openRecipe} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Recipe/addRecipe">
            {Checkpermissions("permissionsSubCatName", "Recipe Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Recipe" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/Recipe/listRecipe">
            {Checkpermissions("permissionsSubCatName", "Recipe List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Recipe List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Deal */}
      {Checkpermissions("permissionsCatName", "Deal") && (
        <ListItemButton onClick={() => setOpenDeal(!openDeal)}>
          <ListItemIcon>
            <RamenDiningIcon />
          </ListItemIcon>
          <ListItemText primary="Deal" />
          {openDeal ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openDeal} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/deal/addDeal">
            {Checkpermissions("permissionsSubCatName", "Deal Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Deal" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/deal/dealList">
            {Checkpermissions("permissionsSubCatName", "Deal List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Deal List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Hall */}
      {Checkpermissions("permissionsCatName", "Hall") && (
        <ListItemButton onClick={() => setOpenhall(!openhall)}>
          <ListItemIcon>
            <AddBusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Hall" />
          {openhall ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openhall} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Hall/addHall">
            {Checkpermissions("permissionsSubCatName", "Hall Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Hall" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Hall/listHall">
            {Checkpermissions("permissionsSubCatName", "Hall List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Hall List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Table */}
      {Checkpermissions("permissionsCatName", "Table") && (
        <ListItemButton onClick={() => setOpenTable(!openTable)}>
          <ListItemIcon>
            <TableRestaurantIcon />
          </ListItemIcon>
          <ListItemText primary="Table" />
          {openTable ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openTable} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Table/addTable">
            {Checkpermissions("permissionsSubCatName", "Table Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Table" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Table/uploadTable">
            {Checkpermissions("permissionsSubCatName", "Table Upload") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Upload Table" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Table/listTable">
            {Checkpermissions("permissionsSubCatName", "Table List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Table List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>

      {/* Vendor */}
      {Checkpermissions("permissionsCatName", "Vendor") && (
        <ListItemButton onClick={() => setOpenVendor(!openVendor)}>
          <ListItemIcon>
            <PersonAddAlt1Icon />
          </ListItemIcon>
          <ListItemText primary="Vendor" />
          {openVendor ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openVendor} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/Vendor/addVendor">
            {Checkpermissions("permissionsSubCatName", "Vendor Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Vendor" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/inventory/Vendor/listVendor">
            {Checkpermissions("permissionsSubCatName", "Vendor List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Vendor List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* uom */}
      {Checkpermissions("permissionsCatName", "UOM") && (
        <ListItemButton onClick={() => setOpenUom(!openUom)}>
          <ListItemIcon>
            <AddBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Uom" />
          {openUom ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openUom} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/uom/adduom">
            {Checkpermissions("permissionsSubCatName", "UOM Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Uom" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          <NavLink to="/inventory/uom/listUom">
            {Checkpermissions("permissionsSubCatName", "UOM List") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Uom List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* {Checkpermissions("permissionsCatName","UOM")&&
<NavLink to="/inventory/uomlist" style={{textDecoration:'none',color:'black'}}>
<ListItemButton>
<ListItemIcon>
 <AutoStoriesIcon />
</ListItemIcon>
<ListItemText primary="UOM List" />
</ListItemButton>
</NavLink>} */}
    </List>
  );
  //User Listing for Inventory
  let inventoryListUser = (
    <List>
      <ListItemButton onClick={() => setPurchaseorder(!purchaseorder)}>
        <ListItemIcon>
          <StorefrontIcon />
        </ListItemIcon>
        <ListItemText primary="PURCHASE ORDER" />
        {purchaseorder ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={purchaseorder} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/addpurchase">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBusinessIcon />
              </ListItemIcon>
              <ListItemText primary="Add Purchase Order" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/purchasedetails">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReorderIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase Order Details" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/purchasereport">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <FeaturedPlayListIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase Order Report" />
            </ListItemButton>
          </NavLink>
          {/* <NavLink to ="/inventory/approvepurchase">
       <ListItemButton sx={{ pl: 4 }}>
         <ListItemIcon>
         <RuleIcon/>
         </ListItemIcon>
         <ListItemText primary="Approve Purchase Order" />
       </ListItemButton>
       </NavLink> */}
        </List>
      </Collapse>

      {/* IGP */}

      <ListItemButton onClick={() => setIgp(!igp)}>
        <ListItemIcon>
          <DoorSlidingIcon />
        </ListItemIcon>
        <ListItemText primary="IGP" />
        {igp ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={igp} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* /inventory/addigp */}
          <NavLink to="/inventory/addigp">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddHomeIcon />
              </ListItemIcon>
              <ListItemText primary="ADD IGP" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/igpdetails">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <GridViewIcon />
              </ListItemIcon>
              <ListItemText primary="IGP Details" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/igpreport">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <FolderZipIcon />
              </ListItemIcon>
              <ListItemText primary="IGP Report" />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      {/*  Processed Vendors */}

      <ListItemButton onClick={() => setProcessVendors(!processVendors)}>
        <ListItemIcon>
          <AssignmentReturnIcon />
        </ListItemIcon>
        <ListItemText primary="Processed" />
        {processVendors ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={processVendors} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/addprocessed">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddchartIcon />
              </ListItemIcon>
              <ListItemText primary="Add Processed" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/processeddetail">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <SourceIcon />
              </ListItemIcon>
              <ListItemText primary="Processed Details" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/processedreport">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DriveFileMoveIcon />
              </ListItemIcon>
              <ListItemText primary="Processed Report" />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
      {/* INSURANCE */}

      <ListItemButton onClick={() => setIssuance(!issuance)}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="ISSUANCE" />
        {issuance ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={issuance} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/inventory/addissuance">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddchartIcon />
              </ListItemIcon>
              <ListItemText primary="Add Issuance" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/issuancedetail">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Issuance Details" />
            </ListItemButton>
          </NavLink>

          <NavLink to="/inventory/issuancereport">
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <WysiwygIcon />
              </ListItemIcon>
              <ListItemText primary="Issuance Report" />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      {/* MATERIAL LEDDGER */}

      {/* /inventory/materialLedger */}

      <NavLink to="/inventory/materialledger">
        <ListItemButton>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="MATERIAL LEDGER1" />
        </ListItemButton>
      </NavLink>
      {/* user permissio */}

      <NavLink to="/inventory/permission">
        <ListItemButton>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="permissions" />
        </ListItemButton>
      </NavLink>
    </List>
  );

  //Admin Listing For the Accounts
  let accountsList = (
    <List>
      {/* level 3 front */}
      {Checkpermissions("permissionsCatName", "Level Three") && (
        <ListItemButton onClick={() => setLevel4(!level4)}>
          <ListItemIcon>
            <Filter4Icon />
          </ListItemIcon>
          <ListItemText primary="Level Three" />
          {level4 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={level4} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/accounts/level4/add">
            {Checkpermissions("permissionsSubCatName", "Add Level Three") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Add Level Three" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level4/details">
            {Checkpermissions(
              "permissionsSubCatName",
              "Level Three Detail"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="Level Three Details" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level4/upload">
            {Checkpermissions(
              "permissionsSubCatName",
              "Level Three Upload"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <UploadFileIcon />
                </ListItemIcon>
                <ListItemText primary="Level Three Upload" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level4/report">
            {Checkpermissions(
              "permissionsSubCatName",
              "Level Three Report"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PollIcon />
                </ListItemIcon>
                <ListItemText primary="Level Three Report" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* Add Account */}
      {Checkpermissions("permissionsCatName", "Level Four") && (
        <ListItemButton onClick={() => setLevel5(!level5)}>
          <ListItemIcon>
            <Filter4Icon />
          </ListItemIcon>
          <ListItemText primary="Add Account" />
          {level5 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={level5} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/accounts/level5/add">
            {Checkpermissions("permissionsSubCatName", "Add Level Four") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="Add Account" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level5/details">
            {Checkpermissions("permissionsSubCatName", "Level Four Detail") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PermMediaIcon />
                </ListItemIcon>
                <ListItemText primary="Level Four Details" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level5/upload">
            {Checkpermissions("permissionsSubCatName", "Level Four Upload") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <UploadIcon />
                </ListItemIcon>
                <ListItemText primary="Level Four Upload" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/level5/report">
            {Checkpermissions("permissionsSubCatName", "Level Four Report") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <DataThresholdingIcon />
                </ListItemIcon>
                <ListItemText primary="Level Four Report" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* voucher */}
      {Checkpermissions("permissionsCatName", "Voucher") && (
        <ListItemButton onClick={() => setOpenVoucher(!openVoucher)}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Voucher" />
          {openVoucher ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      <Collapse in={openVoucher} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/accounts/vouchers/cashpayment">
            {Checkpermissions("permissionsSubCatName", "CPV") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PaymentsIcon />
                </ListItemIcon>
                <ListItemText primary="Cash Payment Voucher" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/vouchers/cashreceipt">
            {Checkpermissions("permissionsSubCatName", "CRV") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <RequestQuoteIcon />
                </ListItemIcon>
                <ListItemText primary="Cash Receipt Voucher" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/vouchers/bankpayment">
            {Checkpermissions("permissionsSubCatName", "BPV") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="Bank Payment Voucher" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/vouchers/bankreceipt">
            {Checkpermissions("permissionsSubCatName", "BRV") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="Bank Receipt Voucher" />
              </ListItemButton>
            )}
          </NavLink>

          <NavLink to="/accounts/vouchers/journal">
            {Checkpermissions("permissionsSubCatName", "JV") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StickyNote2Icon />
                </ListItemIcon>
                <ListItemText primary="Journal Voucher" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* voucher verification */}
      {Checkpermissions("permissionsCatName", "Voucher Verification") && (
        <NavLink
          to="/accounts/voucherverification"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DomainVerificationIcon />
            </ListItemIcon>
            <ListItemText primary="Voucher Verification" />
          </ListItemButton>
        </NavLink>
      )}
      {/* voucher search */}
      {Checkpermissions("permissionsCatName", "Voucher Search") && (
        <NavLink
          to="/accounts/vouchersearch"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Voucher Search" />
          </ListItemButton>
        </NavLink>
      )}
      {/* account Ledger */}
      {Checkpermissions("permissionsCatName", "Accounts Ledger") && (
        <NavLink
          to="/accounts/ledger"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItemButton>
        </NavLink>
      )}
      {/* trial balance */}
      {Checkpermissions("permissionsCatName", "Trail Balance Report") && (
        <NavLink
          to="/accounts/trailbalance"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText primary="Trail Balance" />
          </ListItemButton>
        </NavLink>
      )}
      {Checkpermissions("permissionsCatName", "Trail Balance Report") && (
        <NavLink
          to="/accounts/chartofAccount"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText primary="Chart Of Account" />
          </ListItemButton>
        </NavLink>
      )}

      {Checkpermissions("permissionsCatName", "Aging Report") && (
        <NavLink
          to="/accounts/addaging"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Aging Report" />
          </ListItemButton>
        </NavLink>
      )}
      {Checkpermissions("permissionsCatName", "Cash Book Report") && (
        <NavLink
          to="/accounts/cash-book"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Cash Book" />
          </ListItemButton>
        </NavLink>
      )}
      {Checkpermissions("permissionsCatName", "Profit Loss Report") && (
        <NavLink
          to="/accounts/profit-loss"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Profit Loss Statement" />
          </ListItemButton>
        </NavLink>
      )}
      {Checkpermissions("permissionsCatName", "Transaction Summary") && (
        <NavLink
          to="/accounts/transaction-summary"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary="Transaction Summary" />
          </ListItemButton>
        </NavLink>
      )}
    </List>
  );
  //setting tab
  let settingList = (
    <List>
      {/* Master Control */}
      {Checkpermissions("permissionsCatName", "Accounts Integration") && (
        <ListItemButton
          onClick={() => setOpenMasterControl(!openMasterControl)}
        >
          <ListItemIcon>
            <DomainAddIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts Integration" />
          {openMasterControl ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={openMasterControl} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/setting/MasterControl/addMasterControl">
            {Checkpermissions(
              "permissionsSubCatName",
              "Accounts Integration Add"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New Master Control" />
              </ListItemButton>
            )}
          </NavLink>
          <NavLink to="/setting/MasterControl/listMasterControl">
            {Checkpermissions(
              "permissionsSubCatName",
              "Accounts Integration List"
            ) && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="MasterControl List" />
              </ListItemButton>
            )}
          </NavLink>
        </List>
      </Collapse>
      {/* user */}
      {Checkpermissions("permissionsCatName", "User") && (
        <ListItemButton onClick={() => setOpenUser(!openUser)}>
          <ListItemIcon>
            <PersonAddAlt1Icon />
          </ListItemIcon>
          <ListItemText primary="User" />
          {openUser ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      {/* } */}

      <Collapse in={openUser} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to="/setting/addUser">
            {Checkpermissions("permissionsSubCatName", "User Add") && (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText primary="New User" />
              </ListItemButton>
            )}
          </NavLink>
          {/* "/inventory/catagory/listcatagory" */}
          {Checkpermissions("permissionsSubCatName", "User List") && (
            <NavLink to="/setting/listUser">
              {/* {Checkpermissions("permissionsSubCatName","User List")&& */}
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="User List" />
              </ListItemButton>
              {/* } */}
            </NavLink>
          )}
        </List>
      </Collapse>
      {/* day ope */}
      {Checkpermissions("permissionsCatName", "Day Open") && (
        <NavLink
          to="/setting/opening"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText primary="Day Open" />
          </ListItemButton>
        </NavLink>
      )}
      {/* day close */}
      {Checkpermissions("permissionsCatName", "Day Close") && (
        <NavLink
          to="/setting/closing"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DoorSlidingIcon />
            </ListItemIcon>
            <ListItemText primary="Day close" />
          </ListItemButton>
        </NavLink>
      )}
      {/* day report */}
      {Checkpermissions("permissionsCatName", "DayOpen Record") && (
        <NavLink
          to="/setting/dayOpen_record"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Day Open Record" />
          </ListItemButton>
        </NavLink>
      )}
      {/* permissio */}
      {Checkpermissions("permissionsCatName", "Permission") && (
        <NavLink
          to="/setting/permission"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText primary="Permissions" />
          </ListItemButton>
        </NavLink>
      )}
    </List>
  );
  //Store List

  //admin view components

  let adminviewInventory = (
    <List>
      <NavLink to="/admin/vendor">
        <ListItemButton>
          <ListItemIcon>
            <Diversity1Icon />
          </ListItemIcon>
          <ListItemText primary="VENDORS" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/admin/userdetailview">
        <ListItemButton>
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText primary="USER DETAIL VIEW" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/admin/inventory">
        <ListItemButton>
          <ListItemIcon>
            <FactCheckIcon />
          </ListItemIcon>
          <ListItemText primary="INVENTORY" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/admin/admindemanddetails">
        <ListItemButton>
          <ListItemIcon>
            <AddTaskIcon />
          </ListItemIcon>
          <ListItemText primary="DEMAND VERIFICATION" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/admin/purchaseorderverification">
        <ListItemButton>
          <ListItemIcon>
            <ShoppingCartCheckoutIcon />
          </ListItemIcon>
          <ListItemText primary="PURCHASE VERIFICATION" />
        </ListItemButton>
      </NavLink>
    </List>
  );

  let adminviewaccount = (
    <List>
      <NavLink to="/admin/voucherverification">
        <ListItemButton>
          <ListItemIcon>
            <Diversity1Icon />
          </ListItemIcon>
          <ListItemText primary="VOUCHER VERIFICATION" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/admin/accountledger">
        <ListItemButton>
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText primary="LEDGER" />
        </ListItemButton>
      </NavLink>
    </List>
  );

  if (
    props.moduleKey === "UserInventory" ||
    window.localStorage.getItem("module_key") == "user_inventory"
  )
    return true ? inventoryList : inventoryListUser;
  else if (
    props.moduleKey === "UserAccounts" ||
    window.localStorage.getItem("module_key") == "user_accounts"
  )
    return accountsList;
  else if (
    props.moduleKey === "storeList" ||
    window.localStorage.getItem("module_key") == "storeList"
  )
    return storeList;
  else if (
    props.moduleKey === "setting" ||
    window.localStorage.getItem("module_key") == "setting"
  )
    return settingList;
  else return inventoryList;
}
