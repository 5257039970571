import API from "../../../../../global/api";
import jsPDF from "jspdf";
import { sumBy, isEmpty, isUndefined } from "lodash";
import moment from "moment";

export const requestAddReporting = () => {
  return {
    type: "REQUEST_ADD_REPORTING",
  };
};
export const successAddReporting = (data) => {
  return {
    type: "SUCCESS_ADD_REPORTING",
    payload: data,
  };
};
export const errorAddReporting = () => {
  return {
    type: "ERROR_ADD_REPORTING",
  };
};
export const addReporting = (id, data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReporting());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        dispatch(successAddReporting(res.data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddReporting());
        handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestGetReporting = () => {
  return {
    type: "REQUEST_GET_REPORTING",
  };
};
export const successGetReporting = (data) => {
  return {
    type: "SUCCESS_GET_REPORTING",
    payload: data,
  };
};
export const errorGetReporting = () => {
  return {
    type: "ERROR_GET_REPORTING",
  };
};
export const getReporting = (data) => {
  return (dispatch) => {
    dispatch(requestGetReporting());
    API.get(`/reporting?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetReporting(data1));
      })
      .catch((error) => {
        dispatch(errorGetReporting());
      });
  };
};

export const requestGenerateSaleReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_SALE_REPORT_PDF",
  };
};
export const successGenerateSaleReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateSaleReportPDF = () => {
  return {
    type: "ERROR_GENERATE_SALE_REPORT_PDF",
  };
};

export const generateSaleReportPDF = (
  id,
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportPDF());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;
        doc.setFontSize(6);
        const headers = [["Sr#", "Date", "Sale", "Area"]];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.date,
          sumBy(x.data, "totalSaleValue") - x.bookingDiscount,

          x.areaId.areaName,
        ]);
        let content = {
          margin: {
            left: 12,
            right: 35,
            // size:2
          },
          startY: 150,
          styles: { fontSize: 9 },
          head: headers,
          body: data,
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 340, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 280, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 320, 90, 0, 20);
        doc.setFont("bold");
        doc.text("Sales Detail Booker Wise", 340, 120, 0, 20);
        doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        doc.text(`${getData.data[0].bookerId.userName}`, 40, 145, 0, 20);
        doc.text(fromDate, 300, 145, 0, 20);
        doc.text(toDate, 450, 145, 0, 20);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        doc.text(`${sumBy(data, "2")}`, 360, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportPDF());
        handleDangerVisible();
      });
  };
};

export const requestGenerateNetSaleReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_NET_SALE_REPORT_PDF",
  };
};
export const successGenerateNetSaleReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_NET_SALE_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateNetSaleReportPDF = () => {
  return {
    type: "ERROR_GENERATE_NET_SALE_REPORT_PDF",
  };
};
export const generateNetSaleReportPDF = (
  id,
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateSaleReportPDF());
    API.post(`/issuance/issuance_with_bookerId/${id}`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const fromDate = "From Date " + " " + startDate;
        const toDate = "To Date " + " " + endDate;

        doc.setFontSize(6);
        const headers = [["Sr#", "Date", "Sale", "Return", "Net Sale", "Area"]];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.date,
          sumBy(x.data, "totalSaleValue") - x.bookingDiscount,
          sumBy(x.returnProducts, "totalSalePrice"),
          sumBy(x.data, "totalSaleValue") -
            x.bookingDiscount -
            (isEmpty(x.returnProducts) ||
            isUndefined(sumBy(x.returnProducts, "totalSalePrice"))
              ? 0
              : sumBy(x.returnProducts, "totalSalePrice")),
          x.areaId.areaName,
        ]);
        let content = {
          margin: {
            left: 25,
            right: 45,
            // size:2
          },
          startY: 140,
          styles: { fontSize: 9 },
          head: headers,
          body: data,
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 220, 50, 0, 20);
        // doc.setFontSize(13);
        // doc.setFont("TimesNewRoman", "Normal");
        // doc.text(companyAddress, 280, 70, 0, 20);
        // doc.text(`Ph: ${companyPhone}`, 320, 90, 0, 20);
        // doc.setFont("bold");
        // doc.text("Sales Detail Booker Wise", 340, 120, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFontSize(12);
        doc.rect(30, 100, 520, 30);
        doc.text(`${getData.data[0].bookerId.userName}`, 40, 120, 0, 20);
        doc.text(fromDate, 270, 120, 0, 20);
        doc.text(toDate, 410, 120, 0, 20);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        doc.rect(30, finalY + 10, 520, 35);
        doc.text(`Total`, 50, finalY + 30, 0, 20);
        doc.text(`${sumBy(data, "2")}`, 175, finalY + 30);
        doc.text(`${sumBy(data, "3")}`, 255, finalY + 30);
        doc.text(`${sumBy(data, "4")}`, 325, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Sales Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateSaleReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateSaleReportPDF());
        handleDangerVisible();
      });
  };
};

export const requestGenerateStockReportPDF = () => {
  return {
    type: "REQUEST_GENERATE_STOCK_REPORT_PDF",
  };
};
export const successGenerateStockReportPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_STOCK_REPORT_PDF",
    payload: data,
  };
};
export const errorGenerateStockReportPDF = () => {
  return {
    type: "ERROR_GENERATE_STOCK_REPORT_PDF",
  };
};

export const generateStockReportPDF = (data, handleDangerVisible) => {
  console.log(data, "aaaaa");
  let CategoryId = data.CategoryId == "" ? "" : `&category=${data.CategoryId}`;
  let vendorId = data.vendorId == "" ? "" : `&vendorId=${data.vendorId}`;

  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateStockReportPDF());
    API.get(
      `/products/?wareHouseId=${data.warehouseID}${CategoryId}${vendorId}`
    )
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        // const fromDate = "From Date " + " " + startDate;
        // const toDate = "To Date " + " " + endDate;

        doc.setFontSize(6);
        const headers = [
          ["Sr#", "Code", "Product", "Uom", "Qty", "Price", "Value"],
        ];
        const data = getData.data.map((x, i) => [
          i + 1,
          x.code,
          x.name,
          x.uomLabel,
          x.productBalance,
          x.purchasePrice,
          x.productBalance * x.purchasePrice,
        ]);
        let content = {
          margin: {
            left: 15,
            right: 15,
            // size:2
          },
          startY: 150,
          styles: { fontSize: 11 },
          head: headers,
          body: data,
        };
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text(companyName, 230, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 170, 70, 0, 20);
        doc.text(`Ph: ${companyPhone}`, 210, 90, 0, 20);
        doc.setFontSize(20);
        doc.setFont("bold");
        doc.text("Stock Detail", 240, 120, 0, 20);
        // doc.line(330, 125, 480, 125);
        doc.setFont("TimesNewRoman", "Normal");
        doc.setFontSize(12);
        doc.autoTable(content);

        // doc.setFontSize(12);
        // let finalY = doc.lastAutoTable.finalY;
        // doc.rect(30, finalY + 10, 720, 35);
        // doc.text(`Total`, 50, finalY + 30, 0, 20);
        // doc.text(`${sumBy(data, "2")}`, 250, finalY + 30);
        // doc.text(`${sumBy(data, "3")}`, 355, finalY + 30);
        // doc.text(`${sumBy(data, "4")}`, 460, finalY + 30);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Stock Detail Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateStockReportPDF(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateStockReportPDF());
        handleDangerVisible();
      });
  };
};
