import API from "../../../../../global/api";
export const requestAddReturn= () => {
  return {
    type: "REQUEST_ADD_RETURN",
  };
};
export const successAddReturn= (data) => {
  return {
    type: "SUCCESS_ADD_RETURN",
    payload: data,
  };
};
export const errorAddReturn= () => {
  return {
    type: "ERROR_ADD_RETURN",
  };
};
export const addReturn= (data,id, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddReturn());
    API.post(`/issuance/returnIssuance/${id}`, {data:data})
      .then((res) => {
        dispatch(successAddReturn(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddReturn());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetReturn= () => {
  return {
    type: "REQUEST_GET_RETURN",
  };
};
export const successGetReturn= (data) => {
  return {
    type: "SUCCESS_GET_RETURN",
    payload: data,
  };
};
export const errorGetAllReturn= () => {
  return {
    type: "ERROR_GET_RETURN",
  };
};
export const getReturn= (data) => {
  return (dispatch) => {
    dispatch(requestGetReturn());
    API.get(`/return?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetReturn(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllReturn());
      });
  };
};

export const UpdateRequestReturn= () => {
  return {
    type: "UPDATE_REQUEST_RETURN",
  };
};
export const UpdateSuccessReturn= (data) => {
  return {
    type: "UPDATE_SUCCESS_RETURN",
    payload: data,
  };
};

export const UpdateErrorReturn= () => {
  return {
    type: "UPDATE_ERROR_RETURN",
  };
};

export const updateReturn= (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestReturn());
    API.put(
      `/Return/${oldData._id}`,
      {
        // areaId: data.areaId,
        ReturnName: data.ReturnName,
        ReturnDesc: data.ReturnDesc,
        location: data.location,
        ownerName: data.ownerName,
        ownerNumber: data.ownerNumber,
        ownerCnic: data.ownerCnic,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessReturn([data]));
      })
      .catch((error) => dispatch(UpdateErrorReturn()));
  };
};
export const DeleteRequestReturn= () => {
  return {
    type: "DELETE_REQUEST_RETURN",
  };
};
export const DeleteSuccessReturn= (id) => {
  return {
    type: "DELETE_SUCCESS_RETURN",
    id: id,
  };
};
export const DeleteErrorReturn= () => {
  return {
    type: "DELETE_ERROR_RETURN",
  };
};

export const deleteReturn= (id) => {
  return (dispatch) => {
    dispatch(DeleteRequestReturn());
    API.delete(`/Return/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessReturn(id));
      })
      .catch((error) => dispatch(DeleteErrorReturn()));
  };
};
